<template>
  <default-modal
    v-model="modalModel"
    width="550"
  >
    <div class="px-2 pb-2">
      <v-card-title class="font-weight-bold pt-0 justify-center"
        >Relatório de Pedidos</v-card-title
      >
      <v-form ref="orders">
        <v-card-actions>
          <default-text-field
            autofocus
            v-model="orders"
            label="Pedidos"
            :hide-details="false"
            :rules="[rules.isNumeric]"
            :validate-on-blur="false"
            class="mb-n4"
            :disabled="
              !!orderReportBody.dataInicial || !!orderReportBody.dataFinal
            "
          />
        </v-card-actions>
      </v-form>
      <v-form ref="date">
        <v-card-actions class="mb-n4">
          <v-row
            dense
            align="start"
          >
            <v-col cols="6">
              <default-date-picker
                :picker-date.sync="orderReportBody.dataInicial"
                label="Data Inicial"
                :hide-details="false"
                :disabled="!!orderReportBody.pedidos"
                :error="
                  !!orderReportBody.dataFinal && !orderReportBody.dataInicial
                "
              />
            </v-col>
            <v-col cols="6">
              <default-date-picker
                :picker-date.sync="orderReportBody.dataFinal"
                :disabled="!!orderReportBody.pedidos"
                label="Data Final"
                :hide-details="false"
                :error="
                  !!orderReportBody.dataInicial && !orderReportBody.dataFinal
                "
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
      <v-card-actions>
        <default-button
          :loading="isLoading"
          :disabled="!isAnyInputFilled"
          @click="generateOrderReport"
          >Gerar Planilha (.xls)</default-button
        >
      </v-card-actions>
    </div>
  </default-modal>
</template>

<script>
import { mapActions } from "vuex";
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultModal from "../../../shared/components/vuetify/DefaultModal.vue";
import DefaultDatePicker from "@/shared/components/vuetify/DefaultDatePicker.vue";
import DefaultTextField from "@/shared/components/vuetify/DefaultTextField.vue";

export default {
  name: "OrderReport",
  components: {
    DefaultButton,
    DefaultDatePicker,
    DefaultModal,
    DefaultTextField,
  },
  props: {
    value: {},
  },
  data() {
    return {
      orderReportBody: {
        dataInicial: "",
        dataFinal: "",
        pedidos: "",
      },
      isLoading: false,
    };
  },
  methods: {
    ...mapActions("orderSupervision", ["generateOrderReport"]),
    async generateOrderReport() {
      if (!this.orderReportBody.pedidos || !this.$refs.orders.validate()) {
        if (!this.isDateComplete) {
          return;
        }
      }
      this.isLoading = true;
      await this.$store.dispatch("orderSupervision/generateOrderReport", {
        body: {
          ...(this.isDateComplete
            ? {
                dataInicial: this.orderReportBody.dataInicial,
                dataFinal: this.orderReportBody.dataFinal,
              }
            : {}),
          ...(this.splittedOrders.length > 0
            ? { pedidos: this.splittedOrders }
            : {}),
        },
        fileName: this.fileName,
        fileFormat: "xls",
      });
      this.isLoading = false;
    },
    transformDateFormat(dateString) {
      return dateString.replace(/^(\d{4})-(\d{2})-(\d{2})$/, "$3-$2-$1");
    },
  },
  computed: {
    orders: {
      get() {
        return this.splittedOrders.join(", ");
      },
      set(newValue) {
        this.orderReportBody.pedidos = newValue;
      },
    },
    modalModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    rules() {
      return {
        isNumeric: (value) =>
          /^[0-9\s,]*$/.test(value) ||
          "Pedidos devem conter apenas caracteres numéricos, separados por espaço e/ou vírgula",
      };
    },
    isDateComplete() {
      return (
        !!this.orderReportBody.dataInicial && !!this.orderReportBody.dataFinal
      );
    },
    isAnyInputFilled() {
      return (
        !!this.orderReportBody.dataInicial ||
        !!this.orderReportBody.dataFinal ||
        !!this.orderReportBody.pedidos
      );
    },
    splittedOrders() {
      return (
        this.orderReportBody.pedidos
          ?.split(/\s*,\s*|\s+/)
          ?.filter((order) => order.trim() !== "") ?? []
      );
    },
    fileName() {
      let fileName = "Relatório de Pedidos";
      if (this.isDateComplete) {
        fileName += ` (de ${this.transformDateFormat(
          this.orderReportBody.dataInicial
        )} a ${this.transformDateFormat(this.orderReportBody.dataFinal)})`;
      }
      return fileName;
    },
  },
};
</script>

<style scoped></style>
