<template>
  <v-container
    fluid
    class="pa-3 text--text"
  >
    <the-header />
    <the-listing />
  </v-container>
</template>

<script>
import TheHeader from "../components/TheHeader.vue";
import TheListing from "../components/TheListing.vue";
export default {
  name: "OrderSupervision",
  components: {
    TheHeader,
    TheListing,
  },
};
</script>

<style scoped></style>
