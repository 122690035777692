<template>
  <default-table-head>
    <v-col
      cols="2"
      class="border-r py-2"
    >
      Pedido
    </v-col>
    <v-col
      cols="4"
      class="border-r py-2 text-truncate"
      >Cliente</v-col
    >
    <v-col
      cols="3"
      class="py-2 border-r"
      >Transportadora</v-col
    >
    <v-col
      cols="2"
      class="py-2 text-truncate"
      >Status</v-col
    >
    <v-col
      cols="1"
      class="pa-0"
    >
      <default-fab
        tooltip-text="Relatório de Pedidos"
        @click="openOrderReportModal"
        ><v-icon>mdi-file-download</v-icon></default-fab
      >
    </v-col>
    <order-report
      v-model="isOrderReportModalOpen"
      :key="orderReportModalKey"
    />
  </default-table-head>
</template>

<script>
import DefaultTableHead from "@/shared/components/vuetify/DefaultTableHead.vue";
import DefaultFab from "@/shared/components/vuetify/DefaultFab.vue";
import OrderReport from "./OrderReport.vue";

export default {
  name: "ListingHead",
  components: {
    DefaultFab,
    OrderReport,
    DefaultTableHead,
  },
  data() {
    return {
      isOrderReportModalOpen: false,
      orderReportModalKey: 0,
    };
  },
  methods: {
    openOrderReportModal() {
      this.orderReportModalKey++;
      this.isOrderReportModalOpen = true;
    },
  },
};
</script>

<style scoped></style>
