<template>
  <v-form ref="form">
    <v-row
      justify="center"
      align="center"
      @keyup.enter="filter"
      dense
    >
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <default-text-field
          label="Pedido"
          v-model="order"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <default-text-field
          label="Transportadora"
          v-model="carrier"
        />
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
      >
        <default-date-picker
          label="Data Inicial"
          :picker-date.sync="startDate"
        />
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
      >
        <default-date-picker
          label="Data Final"
          :picker-date.sync="endDate"
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="2"
      >
        <default-filter-button
          @filter="filter"
          @cleanFilter="cleanFilter"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultDatePicker from "@/shared/components/vuetify/DefaultDatePicker.vue";
import DefaultFilterButton from "@/shared/components/vuetify/DefaultFilterButton.vue";
import DefaultTextField from "@/shared/components/vuetify/DefaultTextField.vue";

export default {
  name: "TheHeader",
  components: {
    DefaultDatePicker,
    DefaultFilterButton,
    DefaultTextField,
  },
  methods: {
    ...mapActions("orderSupervision", [
      "orders",
      "setUrlParams",
      "setCurrentPageToFirstPage",
    ]),
    filter() {
      this.setCurrentPageToFirstPage();
      this.orders();
    },
    cleanFilter() {
      this.$refs.form.reset();
    },
  },
  computed: {
    ...mapGetters("orderSupervision", ["getUrlParams"]),
    order: {
      get() {
        return this.getUrlParams.numeroPedido;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, numeroPedido: newValue });
      },
    },
    carrier: {
      get() {
        return this.getUrlParams.transportadoraNome;
      },
      set(newValue) {
        this.setUrlParams({
          ...this.getUrlParams,
          transportadoraNome: newValue,
        });
      },
    },
    startDate: {
      get() {
        return this.getUrlParams.dataInicio;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, dataInicio: newValue });
      },
    },
    endDate: {
      get() {
        return this.getUrlParams.dataFinal;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, dataFinal: newValue });
      },
    },
  },
};
</script>

<style scoped></style>
